// schemas/schema.js

export const schema = {
  Kreditnehmer: {
    // Single structure for a single borrower
    Vorname: "",
    Nachname: "",
    Geburtsdatum: "",
    Zivilstand: "",
    Nationalität: "",
    Aufenthaltsbewilligung: "",
    Beruf: "",
    "Pensum %": 0,
    Ausbildung: "",
    Arbeitgeber: "",
    Telefon: "",
    Email: "",
    Kinder: [{ Name: "", Alter: 0 }],
    Einkommensverhältnisse: {
      "Bruttoeinkommen CHF": 0,
      "Nettoeinkommen CHF": 0,
      "Total Bruttoeinkommen CHF": 0,
      Bonus: [{ Jahr: 0, Summe: 0, "Bonus anrechenbar %": 50 }],
      Verpflichtungen: {
        "Leasing CHF": 0,
        "Privatkredit CHF": 0,
        "Unterhaltszahlungen CHF": 0,
        "Aktuelle Miete CHF": 0,
      },
    },
    Vermögensverhältnisse: {
      "Cashkontos CHF": 0,
      "Depotkontos CHF": 0,
      "Pensionskasse CHF": 0,
      "Säule 3A CHF": 0,
      "Schenkung / Erbvorbezug CHF": 0,
      "Darlehen CHF": 0,
    },
  },
  Objekt: {
    Wohnungsnummer: "",
    Vertragsart: "Kaufobjekt/Refinanzierung",
    "Verkaufpreis (Kaufpreis Objekt, inkl. PP) CHF": 0,
    "Kaufpreis PP CHF": 0,
    "Refinanzierung Objektwert CHF": 0,
    "Refinanzierung Tranche": 0,
    "Refinanzierung Volumen CHF": 0,
    "Refinanzierung Laufzeit": 0,
    "Strasse & Nummer": "",
    "PLZ & Ortschaft": "",
    Haustyp: "",
    "Wohnfläche (Nettowohnfläche) m2": 0,
    "Aussenbereich (Balkon, Terrassenfläche) m2": 0,
    "Kubatur Total m3": 0,
    "Geschoss (Lage in Gebäude , Stockwerk)": "",
    "Wertquote CHF": 0,
    Heizsystem: "",
    "Grundstücksfläche m2": 0,
    Fertigstellungsjahr: 0,
    Renovationsjahr: 0,
    "Renovationskosten CHF": 0,
    Eigentum: "",         // "Bestand" / "Neukauf"
    Investitionstyp: "",  // "Eigenheim" / "Rendite" / "Ferien"
  },
};
