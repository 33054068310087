// classificationUtils.js

// Classes we expect
const CLASSES = [
  'Kreditnehmer',
  'Objekte',
  'Einkommensverhältnisse',
  'Vermögensverhältnisse',
  'Keine'
];

export async function classifyDocuments(parseResults, queryGenerativeModel, modelParams) {
  const classifiedResults = [...parseResults];

  for (let i = 0; i < classifiedResults.length; i++) {
    const { text, filename } = classifiedResults[i];

    // Prompt to classify the text:
    const prompt = `
      Bitte klassifiziere den folgenden Dokumenteninhalt in eine der folgenden Klassen:
      [${CLASSES.join(', ')}]

      Wenn keines passt, nimm "Keine".

      Dokument: ${text.slice(0, 2000)} 
      (kann abgeschnitten sein, wenn sehr lang)

      Ausgabeformat (JSON): 
      {
        "filename": "...",
        "class": "Kreditnehmer"|"Objekte"|"Einkommensverhältnisse"|"Vermögensverhältnisse"|"Keine"
      }
    `;

    const messages = [
      { role: "system", content: "You are a helpful assistant for extracting structured data." },
      { role: "user", content: prompt },
    ];

    try {
      const classificationResponse = await queryGenerativeModel(modelParams.provider, modelParams.model, messages, modelParams.response_format);
      const parsed = JSON.parse(classificationResponse);

      // Store classification in parseResults
      classifiedResults[i].documentClass = parsed.class;
    } catch (err) {
      console.error('Classification failed', err);
      classifiedResults[i].documentClass = 'Keine'; // fallback
    }
  }

  return classifiedResults;
}
