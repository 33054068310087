// utils/attributeUtils.js

export async function extractAttributesFromText(
  text,
  schema,
  entity,
  source,
  queryGenerativeModel,
  modelParams
) {
  // Create a prompt based on the schema and the target entity
  const prompt = 
  `
  Extract attributes defined in the schema from the provided JSON entity and input text. Assign 'null' to attributes not found in the input text. Present the output as a JSON array, where each object includes 'attribute' (attribute name), 'description' (matched text), and 'value' (extracted value)

  Entity: 
  ${JSON.stringify(entity, null, 2)}

  Attributes to Extract: 
  ${Object.keys(schema).map((key) => `- ${key}`).join("\n")}

  Input Text:
  ${text}

  Output Format:
  [
    {
      "attribute": "Attribute Name",
      "description": "Matched text",
      "value": "Extracted value"
    },
    ...
  ]
  `;

  const messages = [
    { role: "system", content: "You are a helpful assistant for extracting structured data." },
    { role: "user", content: prompt },
  ];

  try {
    // Query the LLM using the generative model API
    const response = await queryGenerativeModel(
      modelParams.provider,
      modelParams.model,
      messages,
      modelParams.response_format
    );

    // Parse the response to JSON
    const extractedAttributes = JSON.parse(response);

    // Filter out attributes with null values and add the source to each attribute
    return extractedAttributes
      .filter((attr) => attr.value !== null) // Exclude attributes with null values
      .map((attr) => ({
        ...attr,
        source,
      }));
  } catch (error) {
    console.error("Error extracting attributes:", error);
    return [];
  }
}
