import React, { useState } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  Collapse,
  Tooltip,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  Article as ArticleIcon,
  Folder as FolderIcon,
  FolderOpen as FolderOpenIcon,
} from '@mui/icons-material';

const HierarchyVisualization = ({ sections = {}, hierarchy = {}, onSectionSelect, selectedSectionId }) => {
  const [expandedSections, setExpandedSections] = useState(new Set());
  console.log('HierarchyVisualization props:', { sections, hierarchy }); // Debug log

  const toggleSection = (sectionId) => {
    const newExpanded = new Set(expandedSections);
    if (newExpanded.has(sectionId)) {
      newExpanded.delete(sectionId);
    } else {
      newExpanded.add(sectionId);
    }
    setExpandedSections(newExpanded);
  };

  const getSectionType = (section) => {
    if (!section || !hierarchy[section.id]) return <ArticleIcon />;
    const hasChildren = hierarchy[section.id]?.children?.length > 0;
    if (hasChildren) {
      return expandedSections.has(section.id) ? <FolderOpenIcon /> : <FolderIcon />;
    }
    return <ArticleIcon />;
  };

  const renderSection = (sectionId, level = 0) => {
    const section = sections[sectionId];
    if (!section) return null;

    const hasChildren = hierarchy[sectionId]?.children?.length > 0;
    const isExpanded = expandedSections.has(sectionId);
    const isSelected = selectedSectionId === sectionId;

    return (
      <Box key={sectionId}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: level * 3,
            py: 0.5,
            borderLeft: isSelected ? 3 : 0,
            borderColor: 'primary.main',
            bgcolor: isSelected ? 'action.selected' : 'transparent',
            '&:hover': {
              bgcolor: 'action.hover',
            },
            cursor: 'pointer',
          }}
          onClick={() => onSectionSelect?.(sectionId)}
        >
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              toggleSection(sectionId);
            }}
            sx={{ visibility: hasChildren ? 'visible' : 'hidden' }}
          >
            {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flex: 1,
              overflow: 'hidden',
            }}
          >
            {getSectionType(section)}
            <Tooltip title={section.originalText?.substring(0, 200) + "..." || ""}>
              <Typography
                noWrap
                variant="body2"
                sx={{
                  fontWeight: hasChildren ? 600 : 400,
                }}
              >
                {section.identifier || ""} {section.title || ""}
              </Typography>
            </Tooltip>
          </Box>
        </Box>

        <Collapse in={isExpanded}>
          {hasChildren && hierarchy[sectionId]?.children?.map(childId =>
            renderSection(childId, level + 1)
          )}
        </Collapse>
      </Box>
    );
  };

  // Get root sections (those without parents)
  const getRootSections = () => {
    console.log('Getting root sections from:', { hierarchy, sections }); // Debug log
    if (!hierarchy || !sections) return [];
    const rootSections = Object.keys(hierarchy).filter(
      sectionId => !hierarchy[sectionId]?.parent && sections[sectionId]
    );
    console.log('Root sections found:', rootSections); // Debug log
    return rootSections;
  };

  // If no sections or hierarchy, show empty state
  if (!sections || !hierarchy || Object.keys(sections).length === 0) {
    return (
      <Card variant="outlined">
        <CardHeader title="Dokumenthierarchie" sx={{ bgcolor: 'grey.100' }} />
        <CardContent>
          <Typography color="textSecondary">
            No sections available
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card variant="outlined">
      <CardHeader
        title="Dokumenthierarchie"
        sx={{ bgcolor: 'grey.100' }}
        action={
          <IconButton
            onClick={() => {
              if (expandedSections.size === 0) {
                setExpandedSections(new Set(Object.keys(sections)));
              } else {
                setExpandedSections(new Set());
              }
            }}
          >
            {expandedSections.size === 0 ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
        }
      />
      <CardContent sx={{ p: 1 }}>
        <Box sx={{ 
          maxHeight: '400px', 
          overflow: 'auto',
          border: 1,
          borderColor: 'divider',
          borderRadius: 1,
          bgcolor: 'background.paper'
        }}>
          {getRootSections().map(sectionId => renderSection(sectionId))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default HierarchyVisualization;