// utils/processUtils.js

import { extractPeople, extractObjects } from "./entityUtils";
import { extractAttributesFromText } from "./attributeUtils";

export async function identifyEntities(parsedDocuments, queryGenerativeModel, modelParams) {
  const people = [];
  const investmentObjects = [];

  for (const doc of parsedDocuments) {
    try {
      const docPeople = await extractPeople(doc.text, queryGenerativeModel, modelParams) || [];
      const docObjects = await extractObjects(doc.text, queryGenerativeModel, modelParams) || [];

      if (!Array.isArray(docPeople)) {
        console.warn(`Invalid response for people in document ${doc.filename}. Skipping.`);
        continue;
      } else {
        people.push(...docPeople.map((person) => ({ ...person, source: doc.filename })));
      }

      if (!Array.isArray(docObjects)) {
        console.warn(`Invalid response for objects in document ${doc.filename}. Skipping.`);
        continue;
      } else {
        investmentObjects.push(...docObjects.map((obj) => ({ ...obj, source: doc.filename })));
      }

    } catch (error) {
      console.error(`Error processing document ${doc.filename}:`, error);
      continue;
    }
  }

  return { people, investmentObjects };
}

export async function extractAttributes(
  parsedDocuments,
  schema,
  selectedPerson,
  selectedObject,
  queryGenerativeModel,
  modelParams
) {
  const kreditnehmerAttributes = [];
  const objektAttributes = [];

  for (const doc of parsedDocuments) {
    // Extract Kreditnehmer attributes
    const personAttributes = await extractAttributesFromText(
      doc.text,
      schema.Kreditnehmer,
      selectedPerson,
      doc.filename,
      queryGenerativeModel,
      modelParams
    );
    kreditnehmerAttributes.push(...personAttributes);

    // Extract Objekt attributes
    const objectAttributes = await extractAttributesFromText(
      doc.text,
      schema.Objekt,
      selectedObject,
      doc.filename,
      queryGenerativeModel,
      modelParams
    );
    objektAttributes.push(...objectAttributes);
  }

  return { Kreditnehmer: kreditnehmerAttributes, Objekt: objektAttributes };
}