// utils/entityUtils.js

// Extract people using the LLM
export async function extractPeople(text, queryGenerativeModel, modelParams) {
  const prompt = `
1. Extract all potential people mentioned in the following text. Each person should include as much information as available, such as:
  - Vorname (First Name)
  - Nachname (Last Name)
  - Geburtsdatum (Date of Birth)
  - Zivilstand (Marital Status)
  - Nationalität (Nationality)

2. Evaluate if the gathered attributes have plausible values. Is this really the full name of a person?
  
3. If the information is NOT sufficient to identitfy an Person, return an empty list.

  Input Text:
  ${text}

  Output Format:
  [
    {
      "Vorname": "John",
      "Nachname": "Doe",
      "Geburtsdatum": "01.01.1980",
      "Zivilstand": "Verheiratet",
      "Nationalität": "Schweizer"
    },
    ...
  ]
  `;

  const messages = [
    { role: "system", content: "You are a helpful assistant for extracting structured data." },
    { role: "user", content: prompt },
  ];

  try {
    const response = await queryGenerativeModel(modelParams.provider, modelParams.model, messages, modelParams.response_format);
    return JSON.parse(response);
  } catch (error) {
    console.error("Error extracting people:", error);
    return [];
  }
}

// Extract objects using the LLM
export async function extractObjects(text, queryGenerativeModel, modelParams) {
  const prompt = `
1. Extract all potential investment objects (e.g., apartments, houses) mentioned in the following text. For each object, include as much information as available, such as:
  - Strasse & Nummer (Street & Number)
  - PLZ & Ortschaft (Postal Code & City)
  - Wohnungsnummer (Apartment Number)
  - Vertragsart (Type of Contract, e.g., Kaufobjekt or Refinanzierung)
  - Wohnfläche (Nettowohnfläche) m2 (Living Area in m²)

2. Evaluate if the gathered attributes have plausible values for an Appartment, House or any other real estate asset.
  
3. If the information is NOT sufficient to identitfy an Investment Object, return an empty list.

  Input Text:
  ${text}

  Output Format:
  [
    {
      "Strasse & Nummer": "Musterstrasse 1",
      "PLZ & Ortschaft": "8000 Zürich",
      "Wohnungsnummer": "12A",
      "Vertragsart": "Kaufobjekt",
      "Wohnfläche (Nettowohnfläche) m2": 120
    },
    ...
  ]
  `;

  const messages = [
    { role: "system", content: "You are a helpful assistant for extracting structured data." },
    { role: "user", content: prompt },
  ];

  try {
    const response = await queryGenerativeModel(modelParams.provider, modelParams.model, messages, modelParams.response_format);
    return JSON.parse(response);
  } catch (error) {
    console.error("Error extracting objects:", error);
    return [];
  }
}
