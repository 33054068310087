import React from "react";
import { Box, Typography, Autocomplete, TextField, Button } from "@mui/material";

const EntitySelector = ({
  identifiedEntities,
  setSelectedPerson,
  setSelectedObject,
  selectedPerson,
  selectedObject,
  handleAttributeExtraction,
}) => {
  return (
    identifiedEntities && (
      <Box>
        {/* Select Kreditnehmer */}
        <Typography variant="h6" gutterBottom>
          Kreditnehmer auswählen
        </Typography>
        <Autocomplete
          multiple
          options={identifiedEntities.people || []}
          getOptionLabel={(person) =>
            `${person.Vorname} ${person.Nachname}, Quelle: ${person.source}`
          }
          onChange={(event, value) => setSelectedPerson(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Kreditnehmer"
              placeholder="Kreditnehmer auswählen"
            />
          )}
        />

        {/* Select Investitionsobjekt */}
        <Typography variant="h6" gutterBottom marginTop={2}>
          Investitionsobjekt auswählen
        </Typography>
        <Autocomplete
          multiple
          options={identifiedEntities.investmentObjects || []}
          getOptionLabel={(obj) =>
            `${obj["Strasse & Nummer"]}, ${obj["PLZ & Ortschaft"]}, ${obj["Wohnungsnummer"]}, Quelle: ${obj.source}`
          }
          onChange={(event, value) => setSelectedObject(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Investitionsobjekt"
              placeholder="Investitionsobjekt auswählen"
            />
          )}
        />
      </Box>
    )
  );
};

export default EntitySelector;
