import React, { useState, useEffect } from 'react';
import { Modal, LinearProgress, Box, Typography, AppBar, Toolbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AssistantIcon from '@mui/icons-material/Assistant';
import { useAppContext } from '../contexts/AppContext';
import { useAuth } from '../contexts/AuthContext';
import config from '../config';
import { getPhaseName, filterAndFormatDocuments, generatePrompts, context_dir, createEvaluationMessage,convertPhaseToUserFriendlyPhase,convertUseToUserFriendlyName,
    extractAndGroupByIndicatorWithVerbund, uniqueMessgroessenByPhase } from '../utilities/assistantUtils';
import { getUrlForDocument } from '../utilities/pathUtils';
import { cleanHtml } from '../utilities/htmlUtils'; 
import documentData from '../schemas/intermediate_document_data.json';
import extractionSchemas from '../schemas/data_extraction_schemas.json';
import enrichedCategories from '../schemas/enriched_categories.json';
import '../styles/Assistant.css'
import AssistantMain from './AssistantMain';
import AssistantConfiguration from './AssistantConfiguration';
import AssistantOutput from './AssistantOutput';
import AssistantNavigation from './AssistentNavigation';

function Assistant() {
    const [open, setOpen] = useState(false);
    const [currentProcess, setCurrentProcess] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({ additionalInfo: [] }); // Store additional information provided by user
    const [selectedProject, setSelectedProject] = useState('');
    const [activatedContexts, setActivatedContexts] = useState([]);
    const [acceptanceCriteria, setAcceptanceCriteria] = useState([]);
    const [selectedCriteria, setSelectedCriteria] = useState([]);
    const [modelResponse, setModelResponse] = useState('');
    const [questionAnswers, setQuestionAnswers] = useState({});
    const [isEditing, setIsEditing] = useState(false); // Track if the user is in editing mode
    const [editableResponse, setEditableResponse] = useState('');
    const [isProcessing, setIsProcessing] = useState(false); // Track if processing is happening
    const [executionStep, setExecutionStep] = useState(''); // Track background steps during execution
    const [animationClass, setAnimationClass] = useState('');
    const [phases, setPhases] = useState([]);
    const [fachbereiche, setFachbereiche] = useState([]);
    const [selectedPhase, setSelectedPhase] = useState('');
    const [selectedFachbereiche, setSelectedFachbereiche] = useState([]);
    const [folderData, setFolderData] = useState(null);
    const [parseResults, setParseResults] = useState(null);
    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [selectedControlPhase, setSelectedControlPhase] = useState(config.assistant.testing_phase);
    const [selectedUsage, setSelectedUsage] = useState(config.assistant.uses[0]);
    const [extractedAttributes, setExtractedAttributes] = useState({});
    const { getAcceptanceCriteria, queryGenerativeModel, getSearchResultsInDocsStatelessV2, fetchReportResults, getProject, getExtractedData, getFolderListStateless } = useAppContext();
    const { currentTenant } = useAuth();
    const max_elements = 5;
    const max_depth = 3;
    const isProjektdokumentation = currentProcess?.name === "Projektdokumentation";
    const isDataExtraction = currentProcess?.name === "Datenextraktion";
    const isSNBS = currentProcess?.name === "Vorprüfung SNBS";

    // Fetch projects and acceptance criteria on component load
    useEffect(() => {
        if (open) {
            getAcceptanceCriteria().then((criteria) => setAcceptanceCriteria(criteria));
        }
    }, [open]);

     // On page reload, trigger background color change a few times
    useEffect(() => {
        // Start the animation by adding the class
        setAnimationClass('pulse-animation');

        // Remove the animation class after a few seconds
        const timeout = setTimeout(() => {
            setAnimationClass('');
        }, 3000); // Animation duration is 3 seconds, adjust as necessary

        // Cleanup the timeout on component unmount
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (isProjektdokumentation) {
            const uniquePhases = [...new Set(documentData.flatMap(doc => doc.sia_phasen))];
            const uniqueFachbereiche = [...new Set(documentData.flatMap(doc => doc.fachbereiche))];
            setPhases(uniquePhases);
            setFachbereiche(uniqueFachbereiche);
        }
    }, [currentProcess]);
    
    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setCurrentProcess(null);
        setCurrentStep(0);
        setFormData({ additionalInfo: [] });
        setSelectedCriteria([]);
        setActivatedContexts([]);
        setModelResponse('');
        setQuestionAnswers({});
        setFachbereiche([]);
        setSelectedPhase('');
        setSelectedFachbereiche([]);
        setSelectedIndicators([]);
        setParseResults(null);
        setFolderData(null);
        setExtractedAttributes({});
    };

    const handlePrevStep = () => {
        setCurrentStep((prevStep) => {
            // If on the first step, reset the process name by setting currentProcess to null
            if (prevStep === 0) {
                setCurrentProcess(null);
                setCurrentStep(0);
                setFormData({ additionalInfo: [] });
                setSelectedCriteria([]);
                setActivatedContexts([]);
                setModelResponse('');
                setQuestionAnswers({});
                setFachbereiche([]);
                setSelectedPhase('');
                setSelectedFachbereiche([]);
                setSelectedIndicators([]);
                setParseResults(null);
                setFolderData(null);
                setExtractedAttributes({});
            }
            return prevStep - 1;
        });
    };
    
    const handleProcessSelect = (processName) => {
        const selectedProcess = config.assistant.processes.find(process => process.name === processName);
        if (!selectedProcess.questions) {
            selectedProcess.questions = []; // Initialize questions as an empty array if undefined
        }
        setCurrentProcess(selectedProcess);
        setCurrentStep(0);
        setActivatedContexts(selectedProcess.contexts || []); // Ensure contexts is always an array
    };

    const handleEditToggle = () => {
        if (isEditing) {
            // Save the changes and update the model response
            setModelResponse(editableResponse);
            setIsEditing(false);
        } else {
            // Enter editing mode and populate the editable field
            setEditableResponse(modelResponse);
            setIsEditing(true);
        }
    };

    const isFormValid = () => {
        // Check if all required fields are filled
        const isProjectSelected = !!selectedProject;
        const areQuestionsAnswered = currentProcess?.questions.every((_, index) => questionAnswers[index]);
        const isAdditionalInfoFilled = formData.additionalInfo.every(info => info.trim() !== '');
        const isAtLeastOneContextSelected = activatedContexts.length > 0;
        const areEnoughFachbereicheSelected = selectedFachbereiche.length >= 1;
        const isPhaseSelected = selectedPhase !== '';
        const areIndicatorsSelected = selectedIndicators.length > 0;
        const existParseData = parseResults !== null;
    
        if (isProjektdokumentation) {
            return isProjectSelected && areQuestionsAnswered && isAdditionalInfoFilled && isAtLeastOneContextSelected && isPhaseSelected && areEnoughFachbereicheSelected;
        } else if (isDataExtraction) {
            return existParseData;
        } else if (isSNBS) {
            return isProjectSelected && areIndicatorsSelected;
        } else {
            return isProjectSelected && areQuestionsAnswered && isAdditionalInfoFilled && isAtLeastOneContextSelected;
        }
    };

    const getModelParams = () => {
        const model_params = {
            model:'',
            provider:'',
            response_format:''
        };
        if (isDataExtraction) {
            model_params.model=config.models.extra_large.model;
            model_params.provider=config.models.extra_large.provider;
            model_params.response_format = 'text';//'json_object';
        } else {
            model_params.model=config.models.mid.model;
            model_params.provider=config.models.mid.provider;
            model_params.response_format = 'text';
        }
        return model_params;
    }

    const formatAttributesForLLM = (attributes) => {
        let formattedString = '';
      
        for (const [category, attrs] of Object.entries(attributes)) {
          formattedString += `Category: ${category}\n`;
          attrs.forEach(attr => {
            formattedString += `- Attribute: ${attr.attribute}\n  Value: ${attr.value}\n  Source: ${attr.source}\n`;
          });
          formattedString += '\n';
        }
      
        return formattedString;
      };
    
    async function searchRequirementsInMetadata(project, phase, indicatorsList) {
        const results = [];
        const listed_files = await getFolderListStateless(
            project,
            true,
            false
          );
        for (const indicator in indicatorsList) {
            const indicator_evidence_files = [];
            const search_term = indicatorsList[indicator].messgroesseTitles + ", " + indicatorsList[indicator][phase];
            const search_term_list = search_term.split(', ');
            for (const file in listed_files) {
                // Skip folders
                if (listed_files[file].is_dir === true) continue;
                for (const st in search_term_list) {
                    if (listed_files[file].path.toLowerCase().includes(search_term_list[st].toLowerCase())) {
                        indicator_evidence_files.push(listed_files[file]);
                    }
                }        
            }
            if (indicator_evidence_files && indicator_evidence_files.length > 0){
                const indicator_name = indicatorsList[indicator].indicator;
                const messgroesseTitles = indicatorsList[indicator].messgroesseTitles;
                const requirements = indicatorsList[indicator][phase];
                const query = requirements + " " + messgroesseTitles;
                const result = indicator_evidence_files;
                results.push({
                    indicator_name,
                    phase,
                    messgroesseTitles,
                    requirements,
                    query,
                    result,
                });
                //results.push(indicator_evidence_files);
            }
        }
        return results;
    };

    async function searchRequirementsInDocuments(contextPath, phase, indicatorsList) {
        const results = [];
    
        for (const indicator of indicatorsList) {
            try {
                const indicator_name = indicator.indicator;
                const messgroesseTitles = indicator.messgroesseTitles;
                const requirements = indicator[phase];
                const query = requirements + " " + messgroesseTitles;
                if (query) {
                    const result = await getSearchResultsInDocsStatelessV2(
                        config.envs[config.active_env].domain + config.api_path,
                        3,//max_elements,
                        1,//max_depth,
                        contextPath,
                        query
                    );
                    results.push({
                        indicator_name,
                        phase,
                        messgroesseTitles,
                        requirements,
                        query,
                        result,
                    });
                } else {
                    console.warn(`Phase "${phase}" not found for requirement`, requirements);
                }
            } catch (error) {
                console.error(`Error fetching results for phase "${phase}":`, error);
            }
        }
    
        return results;
    };

    async function generateRequirementReport(data = [], metadata_matches = [], content_matches = [], phase, use, model_params, threshold = 0) {
        if (!Array.isArray(data) || data.length === 0) {
            return `
### Keine SNBS Geforderte Nachweisdokumente für die ausgewählte Indikatoren, Nutzung:${convertUseToUserFriendlyName(use)} und Phase: ${convertPhaseToUserFriendlyPhase(phase)}`;
        }
    
        // Group data by indicator to avoid creating duplicate headers for the same indicator
        const groupedData = data.reduce((acc, entry) => {
            if (!acc[entry.indicator]) {
                acc[entry.indicator] = [];
            }
            acc[entry.indicator].push(entry);
            return acc;
        }, {});
    
        const indicatorReports = await Promise.all(Object.entries(groupedData).map(async ([indicatorTitle, entries]) => {
            // Generate indicator details
            const indicatorDetails = `\n## Indikator: ${indicatorTitle}`;
    
            // Process each messgroesse
            const messgroessenDetails = await Promise.all(entries.map(async entry => {
                // Find the match for this messgroesse
                const relevantMetadataMatch = metadata_matches.find(match => match.messgroesseTitles === entry.messgroesseTitles);
                const relevantDocumentMatch = content_matches.find(match => match.messgroesseTitles === entry.messgroesseTitles)
    
                // Process document matches with filtering and sorting
                if (relevantDocumentMatch && Array.isArray(relevantDocumentMatch.result)) {
                    relevantDocumentMatch.result = relevantDocumentMatch.result.map(result => {
                        result.text_sections = result.text_sections
                            .filter(section => (section.score || 0) >= threshold)
                            .sort((a, b) => (b.score || 0) - (a.score || 0));
                        return result;
                    })
                    .filter(result => Array.isArray(result.text_sections) && result.text_sections.length > 0)
                    .filter(result => {
                        if (relevantMetadataMatch && Array.isArray(relevantMetadataMatch.result)) {
                            return !relevantMetadataMatch.result.some(m => m.path === result.file_metadata.path);
                        } else {
                            return result;
                        }
                    });
                }
    
                // Check if there are any valid results
                if (
                    relevantDocumentMatch !== undefined && (!Array.isArray(relevantDocumentMatch.result) || relevantDocumentMatch.result.length === 0) &&
                    relevantMetadataMatch !== undefined && (!Array.isArray(relevantMetadataMatch.result) || relevantMetadataMatch.result.length === 0)
                ) {
                    return `
#### Messgrössen:  
${entry.messgroesseTitles || "Nicht verfügbar"}
#### Keine Dokumente gefunden für ausgewählte Indikatoren & Messgrössen.\n`;
                }
    
                // Prepare sections with conditional rendering
                let reportSections = `
#### Messgrössen:  
${entry.messgroesseTitles || "Nicht verfügbar"}
#### SNBS Geforderte Nachweisdokumente für ${convertPhaseToUserFriendlyPhase(phase)}: 
${cleanHtml(entry[phase] || "Nicht verfügbar")}`;
    
                // Conditionally add metadata evidence if available
                if (relevantMetadataMatch !== undefined && relevantMetadataMatch.result.length > 0) {
                    relevantMetadataMatch.result = relevantMetadataMatch.result.map(file => { 
                        file.file_metadata = {};
                        file.file_metadata.path = "";
                        file.file_metadata.path=file.path; 
                        file.text_sections = [];
                        return file;
                    });
                    const messages = [
                        {
                            role: config.assistant.system_role,
                            content: config.assistant.base_system_prompt
                        },
                        { role: config.assistant.user_role, content: createEvaluationMessage([relevantMetadataMatch]) }
                    ];
                    let queryResponseMeta = "Keine Dokumenten zu prüfen.";
                    if (relevantMetadataMatch.result.length > 0) {
                        queryResponseMeta = await queryGenerativeModel(model_params.provider, model_params.model, messages, model_params.response_format);
                    }
                    const evidenceMetadata = relevantMetadataMatch.result.map(result => `
- [${result.path}](${getUrlForDocument(encodeURI(result.path), result.id)})`).join("\n\n");
    
                    if (evidenceMetadata.trim()) {
                        reportSections += `
#### Dokumente mit relevante **Beschreibungen** gefunden:\n
${evidenceMetadata}\n`;
                    }

                    reportSections += `
\n\n#### Bewertung der gefundene Dokumenten:\n${queryResponseMeta}`
                }
    
                // Conditionally add content evidence if available
                if (relevantDocumentMatch !== undefined) {
                    const messages = [
                        {
                            role: config.assistant.system_role,
                            content: config.assistant.base_system_prompt
                        },
                        { role: config.assistant.user_role, content: createEvaluationMessage([relevantDocumentMatch]) }
                    ];
                    let queryResponse = "Keine Dokumenten zu prüfen.";
                    if (relevantDocumentMatch.result.length > 0) {
                        queryResponse= await queryGenerativeModel(model_params.provider, model_params.model, messages, model_params.response_format);
                    }
                    const evidenceDetails = relevantDocumentMatch.result.map(result => `
- [${result.file_metadata.path}](${getUrlForDocument(encodeURI(result.file_metadata.path), result.file_metadata.id)})`).join("\n\n");
    
                    if (evidenceDetails.trim()) {
                        reportSections += `
\n\n#### Dokumente mit relevante **Inhalte** gefunden:\n
${evidenceDetails}`;
                    }
                    
                    reportSections += `
\n\n#### Bewertung der gefundene Dokumenten:\n${queryResponse}`
                }
                return reportSections;
            }));
    
            return `${indicatorDetails}${messgroessenDetails.join("\n\n")}`;
        }));
        return indicatorReports.join("\n\n\n");
    }

    const handleExecute = async () => {
        if (!isFormValid()) return; // Ensure form is valid before proceeding

        setIsProcessing(true); 
        setExecutionStep('Vorbereitung der Daten und Suchparameter...');
        const contextData = {};
        const projectPath = `/${currentTenant}/${selectedProject}`;
        let project_details = {};
        const model_params = getModelParams();

        // Include questions and corresponding answers in the search string
        const questionAnswerPairs = Object.entries(questionAnswers).map(
            ([index, answer]) => `${currentProcess.questions[index]}: ${answer}`
        );
        const additionalInfoPairs = formData.additionalInfo.map((info, index) => `Zusätzliche Information ${index + 1}: ${info}`);
        const searchString = [
            currentProcess.name, // Process name
            currentProcess.category, // Process category
            currentProcess.description, // Process description
            ...questionAnswerPairs, // Add question-answer pairs
            ...additionalInfoPairs // Additional info provided by the user
        ].filter(Boolean).join(', '); // Join them as a comma-separated string

        setExecutionStep('Abrufen der Daten aus den gewählten Kontexten...');

        for (const context of activatedContexts) {
            const contextPath = context_dir(currentTenant, selectedProject)[context].path;
            if (context === 'strategie' || context === 'protokolle' || context === 'vorgaben') {
                let matches = [];
                try {
                    matches = await getSearchResultsInDocsStatelessV2(
                        config.envs[config.active_env].domain + config.api_path,
                        max_elements,
                        max_depth,
                        contextPath,
                        searchString
                    );
                } catch (error) {
                    console.error(`Failed to fetch matches from ${context_dir(currentTenant, selectedProject)[context].label}:`, error);
                }
                const contextString = matches.map(match => 
                    match.text_sections.map(section => section.text).join(', ')
                ).join(', ');
    
                // Only add non-empty strings to contextData
                if (contextString) {
                    contextData[context] = contextString;
                }
            } else if (context === 'berichte') {
                try {
                    //setLoading(true);
                    const result = await fetchReportResults();
                    // Get today's date in YYYY-MM-DD format for comparison
                    const today = new Date().toISOString().split('T')[0];
                    
                    // Filter data by project and exclude reports created today
                    const filteredData = result
                        .filter(item => item.project === selectedProject && item.creation_date !== today)
                        .sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date)); // Sort by creation_date, most recent first
                    
                    // Filter out the last 5 reports (most recent)
                    const lastNReportsFiltered = filteredData.slice(max_elements);
                    const reportsString = lastNReportsFiltered.map(report => {
                        const documentNames = report.document && typeof report.document === 'object'
                            ? Object.values(report.document).map(doc => doc.name).join(', ')
                            : 'Keine Dokumente';
                        return `Dokumente: ${documentNames}, Name: ${report.name}, Erstellungsdatum: ${report.creation_date}, Text: ${report.text}`;
                    }).join('; ');

                    if (reportsString) {
                        contextData[context] = reportsString;
                    }
                } catch (error) {
                    console.error('Failed to fetch report results:', error);
                }
                // Filter reports and gather data
            } else if (context === 'snbs') {
                console.log('SBS context active!')
                const evidence_data = extractAndGroupByIndicatorWithVerbund(selectedIndicators);
                setExecutionStep('(Schritt 1/4) Eindeutige Indikatoren extrahieren...');
                const filtered_data = uniqueMessgroessenByPhase(evidence_data, selectedControlPhase, selectedUsage);
                setExecutionStep('(Schritt 2/4) Indikatoranforderungen in Dokumenten suchen...');
                const document_matches = await searchRequirementsInDocuments(contextPath, selectedControlPhase, filtered_data); 
                setExecutionStep('(Schritt 3/4) Indikatoranforderungen in Ordnerstrukturen suchen...');
                const metadata_matches = await searchRequirementsInMetadata(selectedProject, selectedControlPhase, filtered_data)
                setExecutionStep('(Schritt 4/4) Daten validieren und Bericht erstellen...');
                let contextString = await generateRequirementReport(filtered_data, metadata_matches, document_matches, selectedControlPhase, selectedUsage, model_params);
                // Only add non-empty strings to contextData
                if (contextString) {
                    contextData[context] = contextString;
                }
            } else if (context === 'projektdetails') {
                try {
                    const projectDetailsArray = await getProject(projectPath);
                    if (projectDetailsArray && projectDetailsArray.length > 0) {
                        const projectDetails = projectDetailsArray[0];
                        const { 
                            start_date = '', 
                            end_date = '', 
                            project_class = 'Unbekannt', 
                            project_category = 'Unbekannt', 
                            predicted_phase = 'Unbekannt', 
                            name = 'Unbekannt' 
                        } = projectDetails;

                        project_details =  {
                            start_date: start_date, 
                            end_date: end_date, 
                            project_class: project_class, 
                            project_category: project_category, 
                            predicted_phase: predicted_phase, 
                            name: name 
                        };

                        const startDateFormatted = start_date ? new Date(start_date).toLocaleDateString() : 'Datum unbekannt';
                        const endDateFormatted = end_date ? new Date(end_date).toLocaleDateString() : 'Datum unbekannt';
                        const projectDetailsString = `Projekt: ${name}, Kategorie: ${project_category}, Klasse: ${project_class}, SOLL Phase: ${getPhaseName(predicted_phase)}, Startdatum: ${startDateFormatted}, Enddatum: ${endDateFormatted}`;

                        if (projectDetailsString) {
                            contextData[context] = projectDetailsString;
                        }
                    } else {
                        console.error('Projekt-Details nicht verfügbar oder das Array ist leer.');
                    }

                } catch (error) {
                    console.error('Failed to fetch project:', error);
                }
            } else if (context === 'aufgaben') {
                try {
                    const tasks = await getExtractedData(projectPath);
                    const tasksString = tasks.map(task => task.description).join(', ');

                    if (tasksString) {
                        contextData[context] = tasksString;
                    }
                } catch (error) {
                    console.error('Failed to fetch tasks:', error);
                }
            } else if (context === 'uploaded_documents') {
                // Append the grouped and formatted documents to the base response content
                const attributeString = formatAttributesForLLM(extractedAttributes);
                contextData[context] = attributeString;
            } else if (context === 'kbob' && contextData['projektdetails']) {
                const filteredDocuments = filterAndFormatDocuments(selectedPhase, selectedFachbereiche, documentData);

                // Format the response content
                const responseContent = 
`
## Projektdokumentation für ${project_details.name}   
**Projektkategorie:** ${project_details.project_category}    
**Projektklasse:** ${project_details.project_class}   
**Erforderliche Dokumente für ${selectedPhase} gemäss KBOB:**   
`;

                // Iterate over each fachbereich group to format documents
                const documentsByFachbereich = Object.keys(filteredDocuments).map(fachbereich => {
                    const documents = filteredDocuments[fachbereich];
                    const formattedDocuments = documents.map((doc, index) => 
`
${index + 1}. **Dokument:** ${doc.document_name}    
**Kategorien**: ${Object.values(doc.categories).filter(category => category.trim() !== "").join(", ") || "Keine Kategorien definiert"}  
**Beschreibung:** ${doc.description}   
**Dokumenttyp:** ${doc.document_types.join(', ')}   
`               ).join('\n');

                    return `### Fachbereich: ${fachbereich}\n${formattedDocuments}`;
                }).join('\n\n');

                // Append the grouped and formatted documents to the base response content
                contextData[context] = `${responseContent}${documentsByFachbereich}`;
            } else if (context === 'uploaded_documents') {
                const flattened_docs = parseResults.map((file) => 'FILE: ' + file.filename + '\nCONTENT: ' + file.text);
                const tenant = currentTenant.trim().replace(/\s/g, '_');
                const prompts = generatePrompts(extractionSchemas['my_hypotheka']);
                //const schema = 'JSON: ' + JSON.stringify(extractionSchemas);
                contextData[context] = prompts + '\n\n' + 'DOCS: ' + flattened_docs[0]/* .join('\n') */;
            }
        }

        if (Object.keys(contextData).length === 0) {
            setModelResponse('Im Projekt wurden keine Daten zur Durchführung des Prozesses gefunden.');
            setIsProcessing(false); // Stop processing
            setExecutionStep(''); // Clear step description
            setCurrentStep(1);
            return;
        }

        setExecutionStep('Generieren der Dokumente...');

        const criteriaDetails = selectedCriteria.map((criteria) => ({
            name: criteria.name,
            description: criteria.description,
            requirements: criteria.return_schema.requirements.map(req => ({
                name: req.name,
                description: req.description
            }))
        }));

        const messages = [
            {
                role: config.assistant.system_role,
                content: config.assistant.base_system_prompt
            },
            {
                role: config.assistant.user_role,
                content: `I want to run the ${currentProcess.name} process of type ${currentProcess.category}, and its goal is: ${currentProcess.description}. Generate an output following these instructions: ${currentProcess.prompt}, based on the answers provided by the user: ${questionAnswerPairs}, additional information: ${additionalInfoPairs}, and other resources provided here: ${Object.values(contextData).join(', ')}. Please validate the provided source data, using the following evaluation criterias: ${JSON.stringify(criteriaDetails, null, 2)}. Include a summary of the validated data. Ensure the output adheres to standard conventions and formats for this type of process/document.`
            }
        ];

        if (isProjektdokumentation) {
            const response = contextData['kbob'];
            setModelResponse(response); 
        } else if (isSNBS) {
            const response = contextData['snbs'];
            setModelResponse(response);
        } else if (isDataExtraction) {
            const response = contextData['uploaded_documents'];
            setModelResponse(response);
        } else {
            const queryResponse = await queryGenerativeModel(model_params.provider, model_params.model, messages, model_params.response_format);
            setModelResponse(queryResponse || 'Ergebnisse konnten nicht generiert werden.');     
        }
        
        setIsProcessing(false); // Stop processing
        setExecutionStep(''); // Clear step description
        setCurrentStep(1);
    };

    const renderCurrentScreen = () => {
        if (!currentProcess) return <AssistantMain currentTenant={currentTenant} handleProcessSelect={handleProcessSelect}/>;

        switch (currentStep) {
            case 0:
                return (
                <AssistantConfiguration 
                    context_dir={context_dir(currentTenant, selectedProject)} selectedProject={selectedProject} snbs={enrichedCategories}
                    selectedCriteria={selectedCriteria} currentProcess={currentProcess} 
                    questionAnswers={questionAnswers} selectedPhase={selectedPhase} 
                    selectedFachbereiche={selectedFachbereiche} fachbereiche={fachbereiche}
                    phases={phases} formData={formData} folderData={folderData} parseResults={parseResults}
                    acceptanceCriteria={acceptanceCriteria} activatedContexts={activatedContexts} 
                    setSelectedPhase={setSelectedPhase} setSelectedFachbereiche={setSelectedFachbereiche} 
                    setQuestionAnswers={setQuestionAnswers} setActivatedContexts={setActivatedContexts}
                    setSelectedCriteria={setSelectedCriteria} setSelectedProject={setSelectedProject}
                    setFormData={setFormData} setFolderData={setFolderData} setParseResults={setParseResults}
                    selectedIndicators={selectedIndicators} setSelectedIndicators={setSelectedIndicators}
                    selectedControlPhase={selectedControlPhase} setSelectedControlPhase={setSelectedControlPhase}
                    selectedUsage={selectedUsage} setSelectedUsage={setSelectedUsage}
                    extractedAttributes={extractedAttributes} setExtractedAttributes={setExtractedAttributes}
                />);
            case 1:
                return (
                <AssistantOutput 
                    isEditing={isEditing}
                    editableResponse={editableResponse}
                    setEditableResponse={setEditableResponse}
                    modelResponse={modelResponse} 
                />);
            default:
                return (
                <AssistantMain currentTenant={currentTenant} handleProcessSelect={handleProcessSelect}/>
            );
        }
    };

    return (
        <React.Fragment>
            <IconButton 
                color="inherit" 
                onClick={handleOpen}
                className={animationClass} 
                sx={{ width: '56px', // Make sure width and height are the same
                    height: '56px', // Keep height equal to width
                    }}
            >
                <AssistantIcon />
            </IconButton>
    
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'auto' }} // Ensure scrolling for long content
            >
                <Box sx={{ width: '80vw', height: '80vh', bgcolor: 'background.paper', 
                display: 'flex', position: 'relative', flexDirection: 'column', overflow: 'hidden'}}>
                    <AppBar position="static" sx={{
                        background: 'linear-gradient(90deg, rgba(120,10,255,1) 0%, rgba(0,197,212,1) 25%, rgba(21,133,255,1) 75%, rgba(21,79,137,1) 100%)'
                    }}>
                        <Toolbar>
                            <AssistantIcon />
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 2 }}>
                                {`Nukleus Assistent ${currentProcess ? '- ' + currentProcess.name : ''}  ${config.assistant.steps[currentStep] !== undefined ? '- ' + config.assistant.steps[currentStep] : ''}`}
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    {isProcessing && <LinearProgress />} 
    
                    <Box 
                        sx={{ 
                            flex: 1, // Take up available space
                            overflowY: 'auto', 
                            p: 5, 
                            mb:5,
                            pointerEvents: isProcessing ? 'none' : 'auto' 
                        }}
                    >
                        {renderCurrentScreen()}
                    </Box>
    
                    {currentProcess && currentStep >= 0 && (
                        <AssistantNavigation 
                            isProcessing={isProcessing} currentStep={currentStep}
                            handlePrevStep={handlePrevStep} handleExecute={handleExecute}
                            isFormValid={isFormValid}
                            executionStep={executionStep} handleEditToggle={handleEditToggle}
                            isEditing={isEditing} handleClose={handleClose}
                        />
                    )}
                </Box>
            </Modal>
        </React.Fragment>
    );
}

export default Assistant;