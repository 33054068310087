import React, { useState, useCallback } from 'react';
import {
  Box, Button, Typography, CircularProgress, Alert,
  List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction,
  IconButton, Chip, Select, MenuItem, FormControl, InputLabel,
  Card, CardContent, CardHeader, styled
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  FolderOpen as FolderIcon,
  Description as FileIcon,
  Error as ErrorIcon,
  PictureAsPdf as PdfIcon,
  Close as CloseIcon,
  Warning as WarningIcon
} from '@mui/icons-material';
import { parsePdfFile } from '../utilities/parseUtils';
import { identifyEntities, extractAttributes } from '../utilities/processUtils';
import { classifyDocuments } from '../utilities/classificationUtils';
import { schema } from "../schemas/schema";
import { useAppContext } from '../contexts/AppContext';
import config from '../config';
import EntitySelector from './AssistantUploadtEntitySelector';

const DropZone = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDragging' && prop !== 'hasData'
})(({ theme, isDragging, hasData }) => ({
  border: '2px dashed',
  borderColor: isDragging
    ? theme.palette.primary.main
    : hasData
    ? theme.palette.success.main
    : theme.palette.grey[300],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  textAlign: 'center',
  backgroundColor: isDragging
    ? alpha(theme.palette.primary.main, 0.05)
    : 'transparent',
  transition: theme.transitions.create(['border-color', 'background-color']),
  cursor: 'pointer'
}));

const FilesList = styled(List)(({ theme }) => ({
  maxHeight: '300px',
  overflow: 'auto',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2)
}));

const AssistantUpload = ({
  folderData, setFolderData,
  parseResults, setParseResults,
  extractedAttributes,
  setExtractedAttributes
}) => {
  const { queryGenerativeModel } = useAppContext();
  const model_params = {
    model: config.models.large.model,
    provider: config.models.large.provider,
    response_format: 'json_object'
  };

  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState(null);

  // Loading States
  const [parsing, setParsing] = useState(false);
  const [classifying, setClassifying] = useState(false);
  const [identifying, setIdentifying] = useState(false);
  const [extracting, setExtracting] = useState(false);

  // User Selections
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState({});

  // Extractions
  const [identifiedEntities, setIdentifiedEntities] = useState(null);
  const [classifiedResults, setClassifiedResults] = useState(null);

  // Will disable buttons if ANY of these states is true:
  const disableAllButtons =
    parsing || classifying || identifying || extracting;

  // Metrics
  const totalFiles = folderData?.files?.length;
  const parsedFiles = parseResults ? parseResults.filter(r => !r.error).length : 0;
  const definedAttributes = Object.values(schema).reduce(
    (acc, category) => acc + Object.keys(category).length,
    0
  );
  const extractedAttributesCount = extractedAttributes
    ? Object.values(extractedAttributes).reduce((acc, attrList) => acc + attrList.length, 0)
    : 0;

  const handleDrop = useCallback(async (e) => {
    e.preventDefault();
    setIsDragging(false);
    setError(null);

    const items = e.dataTransfer.items;
    if (items.length !== 1) {
      setError("Please drop only one folder");
      return;
    }

    const item = items[0];
    const entry = item.webkitGetAsEntry?.() || item.getAsEntry?.();

    if (!entry?.isDirectory) {
      setError("Please drop a folder, not a file");
      return;
    }

    const data = await processFolder([item]);
    setFolderData(data);
  }, [setFolderData]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const processFolder = async (items) => {
    let totalSize = 0;
    const files = [];
    const pdfFiles = [];

    const processEntry = async (entry) => {
      if (entry.isFile) {
        try {
          const file = await new Promise((resolve) => entry.file(resolve));
          totalSize += file.size;
          files.push({
            name: file.name,
            size: file.size,
            path: entry.fullPath,
            type: file.type
          });

          if (file.type === 'application/pdf') {
            pdfFiles.push(file);
          }
        } catch (error) {
          console.error('Error processing file:', error);
        }
      } else if (entry.isDirectory) {
        const dirReader = entry.createReader();
        try {
          const entries = await new Promise((resolve) => {
            dirReader.readEntries(resolve);
          });
          for (const childEntry of entries) {
            await processEntry(childEntry);
          }
        } catch (error) {
          console.error('Error reading directory:', error);
        }
      }
    };

    for (const item of items) {
      const entry = item.webkitGetAsEntry?.() || item.getAsEntry?.();
      if (entry) {
        await processEntry(entry);
      }
    }

    return { totalSize, files, pdfFiles };
  };

  /**
   * Single chained action:
   * Parse PDFs -> Classify -> Identify Entities
   * Uses local variables to avoid timing issues with state updates.
   */
  const handleProcessPdfData = async () => {
    if (!folderData?.pdfFiles.length) {
      setError("No PDF files to process");
      return;
    }

    setError(null);
    setParsing(true);
    setClassifying(true);
    setIdentifying(true);

    try {
      // 1) Parse
      const parseResultsLocal = await Promise.all(
        folderData.pdfFiles.map((file) => parsePdfFile(file))
      );

      // 2) Classify
      const classifiedResultsLocal = await classifyDocuments(
        parseResultsLocal,
        queryGenerativeModel,
        model_params
      );

      // 3) Identify Entities
      const identifiedEntitiesLocal = await identifyEntities(
        classifiedResultsLocal,
        queryGenerativeModel,
        model_params
      );

      // Update states once everything is done
      setParseResults(parseResultsLocal);
      setClassifiedResults(classifiedResultsLocal);
      setIdentifiedEntities(identifiedEntitiesLocal);

    } catch (err) {
      setError("Fehler bei 'PDF Daten verarbeiten': " + err.message);
    } finally {
      setParsing(false);
      setClassifying(false);
      setIdentifying(false);
    }
  };

  const handleAttributeExtraction = async () => {
    try {
      if (!selectedPerson && !selectedObject) {
        setError("Wähle zuerst eine Person oder ein Objekt aus.");
        return;
      }
      setExtracting(true);
      setError(null);

      const results = await extractAttributes(
        classifiedResults,
        schema,
        selectedPerson,
        selectedObject,
        queryGenerativeModel,
        model_params
      );
      setExtractedAttributes(results);
    } catch (err) {
      setError("Error extracting attributes");
    } finally {
      setExtracting(false);
    }
  };

  const formatSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleRemoveAttribute = (category, attrToRemove) => {
    setExtractedAttributes((prev) => {
      const newAttributes = { ...prev };
      if (newAttributes[category]) {
        newAttributes[category] = newAttributes[category].filter(
          (attr) =>
            attr.attribute !== attrToRemove.attribute ||
            attr.value !== attrToRemove.value ||
            attr.source !== attrToRemove.source
        );
        if (newAttributes[category].length === 0) {
          delete newAttributes[category];
        }
      }
      return newAttributes;
    });
  };

  // Handle selection change for attributes with different values
  const handleSelectChange = (category, attributeName, selectedIndex) => {
    setSelectedAttributes((prev) => ({
      ...prev,
      [`${category}-${attributeName}`]: selectedIndex,
    }));
  };

  return (
    <Card elevation={2} sx={{ margin: 'auto' }}>
      <CardHeader
        title={
          <Box display="flex" alignItems="center" gap={1}>
            <FolderIcon color="primary" />
            <Typography variant="h6">Quelldaten für die Extraktion hochladen</Typography>
          </Box>
        }
      />
      <CardContent>
        <DropZone
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          isDragging={isDragging}
          hasData={!!folderData}
        >
          {/* Removed the second error message here */}
          {!folderData ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <FolderIcon
                sx={{
                  fontSize: 48,
                  color: 'text.secondary',
                  mb: 2
                }}
              />
              <Typography variant="body1" color="text.secondary">
                Ordner hier ablegen
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                (Nur Ordner akzeptiert)
              </Typography>
            </Box>
          ) : (
            // If folder data is present, show details
            <>
              <Box mb={2}>
                <Typography variant="h6" gutterBottom>
                  Ordnerinhalte
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="body1">
                    Gesamtgrösse: {formatSize(folderData.totalSize)}
                  </Typography>
                  <Box>
                    <Typography variant="body2">
                      gefundene Dateien: {folderData.files.length}
                    </Typography>
                    <Typography variant="body2">
                      PDF Dateien: {folderData.pdfFiles.length}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Files List with classification Chip */}
              <FilesList>
                {folderData.files.map((file, index) => {
                  // Look for classification result that matches file name
                  let classificationChip = null;
                  if (classifiedResults && classifiedResults.length) {
                    const match = classifiedResults.find(
                      (res) => res.filename === file.name
                    );
                    if (match) {
                      classificationChip = (
                        <Chip
                          label={match.documentClass}
                          color="primary"
                          size="small"
                          sx={{ ml: 2 }}
                        />
                      );
                    }
                  }

                  return (
                    <ListItem
                      key={index}
                      divider={index !== folderData.files.length - 1}
                    >
                      <ListItemIcon>
                        {file.type === 'application/pdf' ? (
                          <PdfIcon sx={{ p: 0, m: 0 }} color="error" />
                        ) : (
                          <FileIcon color="inherit" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={file.name}
                        secondary={
                          <List dense disablePadding>
                            <ListItem key={file.name + file.path + 'path'}>
                              {file.path}
                            </ListItem>
                            <ListItem key={file.name + file.path + 'size'}>
                              {formatSize(file.size)}
                            </ListItem>
                          </List>
                        }
                        primaryTypographyProps={{
                          sx: { textOverflow: 'ellipsis', overflow: 'hidden' }
                        }}
                      />
                      {classificationChip && (
                        <ListItemSecondaryAction>
                          {classificationChip}
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  );
                })}
              </FilesList>
            </>
          )}
        </DropZone>

        {/* Single chained action + attribute extraction */}
        {folderData && (
          <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
            {folderData.pdfFiles?.length > 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleProcessPdfData}
                disabled={disableAllButtons || !folderData.pdfFiles.length}
                startIcon={
                  (parsing || classifying || identifying) && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
              >
                {parsing || classifying || identifying
                  ? 'Verarbeite...'
                  : 'PDF Daten verarbeiten'}
              </Button>
            )}

            {identifiedEntities && (
              <Button
                variant="contained"
                color="success"
                onClick={handleAttributeExtraction}
                disabled={
                  disableAllButtons ||
                  (!selectedPerson && !selectedObject)
                }
              >
                {extracting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  'Attribute extrahieren'
                )}
              </Button>
            )}
          </Box>
        )}

        {/* Entity Selection UI */}
        {identifiedEntities && (
          <EntitySelector
            identifiedEntities={identifiedEntities}
            setSelectedPerson={setSelectedPerson}
            setSelectedObject={setSelectedObject}
            selectedPerson={selectedPerson}
            selectedObject={selectedObject}
            handleAttributeExtraction={handleAttributeExtraction}
          />
        )}

        {/* Render extracted attributes */}
        {extractedAttributes && Object.keys(extractedAttributes).length > 0 && (
          <Box mt={2}>
            <Typography variant="h6" gutterBottom>
              Extrahierte Attribute
            </Typography>
            {Object.entries(extractedAttributes).map(([category, attrs]) => {
              // Group attributes by name
              const groupedAttrs = attrs.reduce((acc, attr) => {
                if (!acc[attr.attribute]) {
                  acc[attr.attribute] = [];
                }
                acc[attr.attribute].push(attr);
                return acc;
              }, {});

              return (
                <Box key={category} mt={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    {category}
                  </Typography>
                  <List>
                    {Object.entries(groupedAttrs).map(([attributeName, attrList]) => {
                      const uniqueValues = [...new Set(attrList.map(a => a.value))];
                      const isRepeating = attrList.length > 1;
                      const hasDifferentValues = uniqueValues.length > 1;
                      const selectedIndex =
                        selectedAttributes[`${category}-${attributeName}`] ?? -1;

                      if (isRepeating && !hasDifferentValues) {
                        // same value, multiple sources
                        const combinedSources = attrList.map(a => a.source);
                        return (
                          <ListItem key={`${category}-${attributeName}`} divider>
                            <ListItemText
                              primary={
                                <span>
                                  <strong>{attributeName}:</strong> {uniqueValues[0]}
                                </span>
                              }
                              secondary={attrList[0].description}
                            />
                            <ListItemSecondaryAction>
                              {combinedSources.map((source, idx) => (
                                <Chip
                                  key={idx}
                                  label={source}
                                  size="small"
                                  sx={{ marginRight: 0.5 }}
                                />
                              ))}
                              <IconButton
                                edge="end"
                                aria-label="remove attribute"
                                onClick={() => handleRemoveAttribute(category, attrList[0])}
                                disabled={disableAllButtons}
                              >
                                <CloseIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      } else if (isRepeating && hasDifferentValues) {
                        // multiple distinct values -> user must pick
                        return (
                          <ListItem
                            key={`${category}-${attributeName}`}
                            divider
                            sx={{
                              backgroundColor: selectedIndex === -1 ? 'yellow' : 'inherit'
                            }}
                          >
                            {selectedIndex === -1 && (
                              <ListItemIcon>
                                <WarningIcon color="warning" />
                              </ListItemIcon>
                            )}
                            <ListItemText
                              primary={
                                <span>
                                  <strong>{attributeName}:</strong>
                                  <FormControl sx={{ ml: 2, minWidth: 200 }} size="small">
                                    <InputLabel
                                      id={`${category}-${attributeName}-label`}
                                    >
                                      Wert auswählen
                                    </InputLabel>
                                    <Select
                                      labelId={`${category}-${attributeName}-label`}
                                      value={selectedIndex}
                                      label="Wert auswählen"
                                      onChange={(e) =>
                                        handleSelectChange(category, attributeName, e.target.value)
                                      }
                                    >
                                      <MenuItem key="none" value={-1}>
                                        Kein Auswahl
                                      </MenuItem>
                                      {attrList.map((attr, idx) => (
                                        <MenuItem key={idx} value={idx}>
                                          {attr.value} ({attr.source})
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </span>
                              }
                              secondary={
                                selectedIndex !== -1
                                  ? attrList[selectedIndex].description
                                  : null
                              }
                            />
                            {selectedIndex !== -1 && (
                              <ListItemSecondaryAction>
                                <Chip
                                  label={attrList[selectedIndex].source}
                                  size="small"
                                  sx={{ marginRight: 1 }}
                                />
                                <IconButton
                                  edge="end"
                                  aria-label="remove attribute"
                                  onClick={() =>
                                    handleRemoveAttribute(
                                      category,
                                      attrList[selectedIndex]
                                    )
                                  }
                                  disabled={disableAllButtons}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            )}
                          </ListItem>
                        );
                      } else {
                        // single attribute
                        return (
                          <ListItem key={`${category}-${attributeName}`} divider>
                            <ListItemText
                              primary={
                                <span>
                                  <strong>{attributeName}:</strong> {attrList[0].value}
                                </span>
                              }
                              secondary={attrList[0].description}
                            />
                            <ListItemSecondaryAction>
                              <Chip
                                label={attrList[0].source}
                                size="small"
                                sx={{ marginRight: 1 }}
                              />
                              <IconButton
                                edge="end"
                                aria-label="remove attribute"
                                onClick={() =>
                                  handleRemoveAttribute(category, attrList[0])
                                }
                                disabled={disableAllButtons}
                              >
                                <CloseIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      }
                    })}
                  </List>
                </Box>
              );
            })}
          </Box>
        )}

        {/* Basic stats/metrics */}
        {folderData && (
          <Box mt={2}>
            <Typography variant="subtitle1">
              Erfolgreich geparste Dateien: {parsedFiles} / {totalFiles}
            </Typography>
            <Typography variant="subtitle1">
              Definierte Attribute: {definedAttributes} / Extrahierte Attribute: {extractedAttributesCount}
            </Typography>
          </Box>
        )}

        {/* Single (final) error message if needed */}
        {error && (
          <Alert severity="error" icon={<ErrorIcon />} sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default AssistantUpload;
