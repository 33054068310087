import React from 'react';
import { useAppContext } from '../contexts/AppContext';
import { Typography, FormControl, InputLabel, MenuItem, Select, Grid, OutlinedInput, Checkbox, ListItemText } from "@mui/material";
import AssistantUpload from './AssistantUpload';
import AssistantStandardProcess from './AssistantStandardProcess';
import AssistantSNBS from './AssistantSNBS';
import AssistantReviewContract from './AssistantReviewContract';

function AssistantConfiguration({
    context_dir, activatedContexts, setActivatedContexts,
    currentProcess, setSelectedProject, selectedProject, 
    questionAnswers, setQuestionAnswers, 
    acceptanceCriteria, setSelectedCriteria, selectedCriteria,  
    selectedPhase, phases, setSelectedPhase, 
    selectedControlPhase, setSelectedControlPhase,
    selectedFachbereiche, fachbereiche, setSelectedFachbereiche,
    formData, setFormData, folderData, setFolderData, parseResults, setParseResults,
    snbs, selectedIndicators, setSelectedIndicators,
    selectedUsage, setSelectedUsage, extractedAttributes, setExtractedAttributes
}) {

    const { userProjects } = useAppContext();

    const handleProjectSelect = (event) => {
        setSelectedProject(event.target.value);
    };

    const handleUsageChange = (event) => {
        setSelectedUsage(event.target.value);
    };

    const handlePhaseChange = (event) => {
        setSelectedControlPhase(event.target.value);
    };

    const handleAnswerChange = (index, value) => {
        setQuestionAnswers((prevAnswers) => ({
            ...prevAnswers,
            [index]: value
        }));
    };

    const handleContextToggle = (context) => {
        const newContexts = activatedContexts.includes(context)
            ? activatedContexts.filter(c => c !== context)
            : [...activatedContexts, context];
        setActivatedContexts(newContexts);
    };

    const handleCriteriaSelect = (event) => {
        const {
            target: { value }
        } = event;
        const selectedCriteriaObjects = acceptanceCriteria.filter(criteria => 
            value.includes(criteria.name)
        );
        setSelectedCriteria(selectedCriteriaObjects); // Save the whole criteria object instead of just names
    };

    const handleAddAdditionalInfo = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            additionalInfo: [...prevFormData.additionalInfo, ''] // Add empty field
        }));
    };

    const handleAdditionalInfoChange = (index, value) => {
        const updatedInfo = [...formData.additionalInfo];
        updatedInfo[index] = value;
        setFormData({ ...formData, additionalInfo: updatedInfo });
    };

    const handleRemoveAdditionalInfo = (index) => {
        const updatedInfo = formData.additionalInfo.filter((_, i) => i !== index);
        setFormData({ ...formData, additionalInfo: updatedInfo });
    };

    const handleSelectionChange = (selectedIndicators) => {
        console.log('Selected Indicators:', selectedIndicators);
      };

    const renderProcessUI = (processType) => {
        switch (processType) {
            case 'Inhaltsvalidierung':
                return (
                    <AssistantReviewContract/>
                );
            case 'Datenextraktion':
                return (
                    <AssistantUpload 
                        folderData={folderData} 
                        parseResults={parseResults} 
                        setFolderData={setFolderData} 
                        setParseResults={setParseResults}
                        extractedAttributes={extractedAttributes} 
                        setExtractedAttributes={setExtractedAttributes}
                    />
                );
            case 'Projektdokumentation':
                return (
                    <React.Fragment>
                        <Typography variant="subtitle1" sx={{ mt: 3 }}>Projektauswahl</Typography>
                        <FormControl fullWidth sx={{ }}>
                            <InputLabel id="project-select-label">Projekt auswählen</InputLabel>
                            <Select
                                labelId="project-select-label"
                                id="project-select"
                                required
                                value={selectedProject}
                                onChange={handleProjectSelect}
                            >
                                {userProjects.map((projectItem) => (
                                    <MenuItem key={projectItem} value={projectItem.split('/').pop()}>
                                        {projectItem.split('/').pop()}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" sx={{ mt: 3 }}>Phase</Typography>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel id="phase-select-label">Phase auswählen</InputLabel>
                                    <Select
                                        labelId="phase-select-label"
                                        id="phase-select"
                                        value={selectedPhase}
                                        onChange={(e) => setSelectedPhase(e.target.value)}
                                        required
                                    >
                                        {phases.map((phase) => (
                                            <MenuItem key={phase} value={phase}>
                                                {phase}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Typography variant="subtitle1">Fachbereiche</Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="fachbereiche-multiselect-label">Fachbereiche auswählen</InputLabel>
                                    <Select
                                        labelId="fachbereiche-multiselect-label"
                                        id="fachbereiche-multiselect"
                                        multiple
                                        value={selectedFachbereiche}
                                        onChange={(event) => setSelectedFachbereiche(event.target.value)}
                                        input={<OutlinedInput label="Fachbereiche auswählen" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        required
                                    >
                                        {fachbereiche.map((fach) => (
                                            <MenuItem key={fach} value={fach}>
                                                <Checkbox checked={selectedFachbereiche.includes(fach)} />
                                                <ListItemText primary={fach} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                );
            case 'Vorprüfung SNBS':
                return(
                    <React.Fragment>
                        <Typography variant="subtitle1" sx={{ mt: 3 }}>Projektauswahl</Typography>
                        <FormControl fullWidth sx={{ }}>
                            <InputLabel id="project-select-label">Projekt auswählen</InputLabel>
                            <Select
                                labelId="project-select-label"
                                id="project-select"
                                required
                                value={selectedProject}
                                onChange={handleProjectSelect}
                            >
                                {userProjects.map((projectItem) => (
                                    <MenuItem key={projectItem} value={projectItem.split('/').pop()}>
                                        {projectItem.split('/').pop()}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ mt: 3 }}>
                                    <InputLabel id="phase-select-label">Phase</InputLabel>
                                    <Select
                                        labelId="phase-select-label"
                                        id="phase-select"
                                        value={selectedControlPhase}
                                        onChange={handlePhaseChange}
                                        label="Phase"
                                    >
                                        <MenuItem value="vorpruefung">Vorprüfung</MenuItem>
                                        <MenuItem value="nachweisKp1">Nachweis KP1</MenuItem>
                                        <MenuItem value="nachweisKp2">Nachweis KP2</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth sx={{ mt: 3 }}>
                                    <InputLabel id="use-select-label">SNBS Nutzungskategorie</InputLabel>
                                    <Select
                                        labelId="use-select-label"
                                        id="use-select"
                                        value={selectedUsage}
                                        onChange={handleUsageChange}
                                        label="SNBS Nutzungskategorie"
                                    >
                                        <MenuItem value="wohnen">Wohnen</MenuItem>
                                        <MenuItem value="verwaltung">Verwaltung</MenuItem>
                                        <MenuItem value="bildungsbauten">Bildungsbauten</MenuItem>
                                        <MenuItem value="erdgeschossnutzung">Erdgeschossnutzung</MenuItem>
                                    </Select>
                                </FormControl>

                                <Typography variant="subtitle1" sx={{ mt: 3 }}>Indikatorenauswahl</Typography>
                                
                                <AssistantSNBS data={snbs} onSelectionChange={handleSelectionChange} selectedIndicators={selectedIndicators} setSelectedIndicators={setSelectedIndicators}/>
                            </Grid>
                        </Grid>
                    </React.Fragment>
            );
            default:
                return (
                    <AssistantStandardProcess
                        currentProcess={currentProcess} userProjects={userProjects} selectedProject={selectedProject}
                        questionAnswers={questionAnswers} handleAnswerChange={handleAnswerChange} handleProjectSelect={handleProjectSelect}
                        formData={formData} handleAdditionalInfoChange={handleAdditionalInfoChange} handleRemoveAdditionalInfo={handleRemoveAdditionalInfo} handleAddAdditionalInfo={handleAddAdditionalInfo}
                        context_dir={context_dir} activatedContexts={activatedContexts} handleContextToggle={handleContextToggle}
                        selectedCriteria={selectedCriteria} handleCriteriaSelect={handleCriteriaSelect} acceptanceCriteria={acceptanceCriteria}
                    />
                );
        }
    };

    return (
        <React.Fragment>
            {renderProcessUI(currentProcess?.name)}
        </React.Fragment>
    );
}

export default AssistantConfiguration;