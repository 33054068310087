import * as pdfjsLib from 'pdfjs-dist';

// Set worker path for pdf.js, deployed locally, loaded dynamically.
pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

// PDF parsing function
export async function parsePdfFile(file) {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
    const maxPages = pdf.numPages;
    let fullText = '';

    for (let pageNum = 1; pageNum <= maxPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const textContent = await page.getTextContent();
      const pageText = textContent.items.map(item => item.str).join(' ');
      fullText += pageText + '\n\n';
    }

    return {
      filename: file.name,
      pages: maxPages,
      text: fullText
    };
  } catch (error) {
    console.error(`Error parsing PDF ${file.name}:`, error);
    return {
      filename: file.name,
      error: error.message
    };
  }
};


export async function parseDocument(file) {
  const text = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        const pdfText = await extractPdfText(reader.result); 
        resolve(pdfText);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
  return text;
}

async function extractPdfText(buffer) {
  return "Extracted text from PDF"; 
}
